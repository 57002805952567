import PropTypes from "prop-types"
import React, { memo } from "react"
import { Text } from "atomize"
import { theme } from "../settings/config"

HeadingText.propTypes = {
    children: PropTypes.any,
    textColor: PropTypes.string,
}

HeadingText.defaultProps = {
    textColor: theme.colors.gunmetal,
}

function HeadingText({ children, ...others }) {
    return (
        <Text
            textSize={{
                xs: "display1",
                md: "display2",
                lg: "display3",
            }}
            textWeight="bold"
            {...others}
        >
            {children}
        </Text>
    )
}

export default memo(HeadingText)
